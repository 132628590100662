<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field expanded class="search-area">
                    <b-input :placeholder="$t('EXAMESLISTA.PROCURARPOR') +colunaSelecionada"
                             v-model="procurar"
                             icon="magnify"
                             expanded
                             icon-clickable
                             @icon-click="loadAsyncData"
                             @input="loadAsyncData"></b-input>
                    <b-dropdown aria-role="list" v-model="colunaSelecionada" expanded>
                        <button class="button is-primary dropdown-opcoes" slot="trigger" slot-scope="{ active }">
                            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                        </button>

                        <b-dropdown-item aria-role="listitem" value="Apelido">{{$t('EXAMESLISTA.APELIDO')}}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="Nome">{{$t('EXAMESLISTA.NOME')}}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="Sinonimia">{{$t('EXAMESLISTA.SINONIMIA')}}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="Setor">{{$t('EXAMESLISTA.SETOR')}}</b-dropdown-item>
                        <hr />
                        <b-dropdown-item aria-role="listitem" :value="coluna" v-for="coluna in colunas" :key="coluna.id">{{coluna.nome}}</b-dropdown-item>
                    </b-dropdown>                             
                </b-field>
            </div>
            <div class="column">
                <div >
                    <router-link v-if="isInRole('exame-alterar')" :to="{ name: 'exame' }" class="button is-success">
                        {{$t('EXAMESLISTA.INCLUIR')}}
                    </router-link>
                </div>
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('EXAMESLISTA.CODIGO')"
                                 v-model="id"
                                 type="number"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 :current-page.sync="page"
                 @page-change="onPageChange"
                 :aria-next-label="$t('EXAMESLISTA.PROXPAGINA')"
                 :aria-previous-label="$t('EXAMESLISTA.PAGANTERIOR')"
                 :aria-page-label="$t('EXAMESLISTA.PAGINA')"
                 :aria-current-label="$t('EXAMESLISTA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column field="id" :label="$t('EXAMESLISTA.ID')">                             
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.id }}
                    </template>                    
                </b-table-column>

                <b-table-column field="Apelido" :label="$t('EXAMESLISTA.APELIDO')" >
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.apelido }}
                    </template>                                          
                </b-table-column>

                <b-table-column  field="Nome" :label="$t('EXAMESLISTA.NOME')" >
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.nome }}
                    </template>                                          
                </b-table-column>

                <b-table-column field="" :label="$t('EXAMESLISTA.ADICIONAL')" >
                    <template v-slot:header="{  }">                                                                              
                            <b-field label="">
                                <b-select :placeholder="$t('EXAMESLISTA.SELECIONECOD')" v-model="colunaDinamica">
                                    <option
                                        v-for="option in colunas"
                                        :value="option.nome"
                                        :key="option.id">
                                        {{ option.nome }}
                                    </option>
                                </b-select>
                            </b-field>                                              
                    </template>
                    <template v-slot="props">
                        {{getCodigo(props.row)}}
                    </template>                                          
                </b-table-column>                          

                <b-table-column  field="Setor" :label="$t('EXAMESLISTA.SETOR')" >
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.setor }}
                    </template>                                          
                </b-table-column>      

                <b-table-column  field="desativado" label="">
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        <b-icon v-show="props.row.desativado" type="is-danger" icon="close-circle" title="Desativado"></b-icon>
                    </template>                      
                    
                </b-table-column>

                <b-table-column  custom-key="actions" numeric>
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        <router-link :to="{ name: 'exame', params: { id: props.row.id }}" class="button is-small is-primary" :title="$t('EXAMESLISTA.EDITAR')">
                            <b-icon icon="pencil" size="is-small"></b-icon>
                        </router-link>
                    </template>                      
                </b-table-column>
            </template>


        </b-table>
    </section>
</template>

<style lang="scss" scoped>
    .search-area {
        .dropdown {
            max-width: 2rem;
        }
    }
</style>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: this.$route.query.page ?? 1,
                perPage: 20,
                selecionado: null,
                id: null,
                colunaDinamica: null,
                colunas: [],
                colunaSelecionada: 'Apelido'
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('EXAMESLISTA.MANUTENCAO'),
                    this.$t('EXAMESLISTA.EXAMES'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
           loadAsyncData: debounce(function () {
                let tipoCodigo = 0;
                let fieldName = this.colunaSelecionada;
                const isObject = (obj) => {
                    const type = typeof obj;
                    return type === 'function' || type === 'object' && !!obj;
                };
                if (this.colunaSelecionada && isObject(this.colunaSelecionada)){
                    tipoCodigo = this.colunaSelecionada.id;
                    fieldName = `Codigo_${tipoCodigo}`;
                    this.selecionaColunaTabela();
                }
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `fieldName=${fieldName}`,
                    `tipoCodigo=${tipoCodigo}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/exames?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        this.$router.replace({query: {procurar: this.procurar, page: this.page }})

                        this.colunas = this.model.codigos?.map(c => {
                            return {
                                ...c,
                                toString: () => {
                                    return c.nome;
                                }
                            }
                        });
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },1000),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.$router.push({ path: `/configuracoes/exame/${this.selecionado.id}` })
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/configuracoes/exame/${this.id}` })
            },
            getCodigo(row){
                if (!this.colunaDinamica) return '';

                return row.codigos?.find(c => c.exameCodigoTipo?.nome === this.colunaDinamica)?.codigo;
            },
            selecionaColunaTabela(){
                if (this.colunaSelecionada){
                    this.colunaDinamica = this.colunaSelecionada.nome;
                }
            }
        },
        mounted() {
            this.loadAsyncData();
        }
    }
</script>
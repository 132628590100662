<template>
	<section>
		<titlebar :title-stack="titleStack"></titlebar>
		<div class="box">
			<div class="columns">
				<div class="column is-6">
                    <b-field label="Número da guia">
                        <b-input type="number" v-model="filtro.filtroGuia"></b-input>
                    </b-field>					
				</div>
				<div class="column is-6">
					<searchIdName :ponto="false" :label="$t('SOROTECA.GELADEIRA')" table="Geladeira" :id.sync="filtro.geladeiraId"/>					
				</div>
			</div>
			<div class="columns">
				<div class="column is-6">
					<searchIdName :ponto="false" :label="$t('SOROTECA.CONTAINER')" table="Container" :id.sync="filtro.containerId"/>					
				</div>
				<div class="column is-6">
					<searchIdName :ponto="false" :label="$t('SISTEMA.PACIENTE')"  table="Paciente" :id.sync="filtro.pacienteId"/>					
				</div>
			</div>

			<div class="columns">
				<div class="column is-6">
                    <b-field :label="$t('SOROTECA.CODIGOAMOSTRA')"  >
                        <b-input type="number" v-model="filtro.amostraCodigoDaAmostra"></b-input>
                    </b-field>	
				</div>
				<div class="column is-6">
					<searchIdName :ponto="false" :label="$t('SOROTECA.SETOR')"  table="Setor" :id.sync="filtro.setorId"/>					
				</div>
			</div>


			<div class="columns">
				<div class="column is-4">
					<periodo
						:dataInicial.sync="filtro.dataInicial"
						:dataFinal.sync="filtro.dataFinal"
						:label="$t('SOROTECA.PERIODOENVIO')"												
					/>

				</div>
				<div class="column is-8">
					
				</div>
			</div>			

			<div class="columns fix-margin">
				<b-button
					type="is-primary"
					icon-left="filter"
					class="center"
					@click.prevent.stop="filterData"
					>{{ $t('SOROTECA.FILTRAR') }}</b-button
				>
			</div>
		</div>

		<div class="box">
			<div class="columns">
				<div class="column is-12">

					<b-table
						v-if="model.lista.length"
						:data="model.lista"
						:loading="isLoading"
						paginated
						striped
						hoverable						
						backend-pagination						
						:total="total"												
						@page-change="onPageChange"
						:aria-next-label="$t('SOROTECA.PROXPAGINA')"
						:aria-previous-label="$t('SOROTECA.PAGINAANTERIOR')"
						:aria-page-label="$t('SOROTECA.PAGINA')"
						:aria-current-label="$t('SOROTECA.PAGINAATUAL')"
						:checked-rows.sync="checkedRows"					
						:checkable="isInRole('soroteca-remover')"																		
						sort-icon-size="is-small">
						<template >
						
							<b-table-column  v-slot="props" field="Amostra" :label="$t('SOROTECA.AMOSTRA')">
								<span>{{ props.row.amostraCodigoDeBarras }}</span><br>
								<span v-show="props.row.amostraRefMae" class="is-size-7"><b>Prim: {{ props.row.amostraRefMaeCodBarras }}</b></span>
							</b-table-column>

							<b-table-column  v-slot="props" field="Paciente" :label="$t('SISTEMA.PACIENTE')">
								{{ props.row.pacienteNome }}
							</b-table-column>

							<b-table-column  v-slot="props" field="Material" :label="$t('SOROTECA.MATERIAL')">
								{{ props.row.materialNome }}
							</b-table-column>

							<b-table-column  v-slot="props"
								field="Conservante"
								:label="$t('SOROTECA.CONSERVANTE')"
								class="wrap-long-cell"
							>
								{{ props.row.conservanteNome }}
							</b-table-column>

							<b-table-column  v-slot="props" field="Em" :label="$t('SOROTECA.EM')">
								{{ props.row.em | datahora }}
							</b-table-column>

							<b-table-column  v-slot="props" field="Exames" :label="$t('SOROTECA.EXAMES')">
								<span
									class="tag"
									v-for="(exame, idx) in props.row.apelidos"
									:key="idx"
									:class="retornaClasse(exame)" :title="retornaTitle(exame)">
									{{ exame.apelido }}
								</span>
							</b-table-column>

							<b-table-column  v-slot="props" field="Multi" :label="$t('SOROTECA.MULTI')">
								<span>{{ props.row.geladeiraNome }}/{{ props.row.containerNome }}/{{ props.row.posicaoNumero }}{{ props.row.posicaoLetra }} </span><br>
								<span v-show="(props.row.amostraRefMae && props.row.containerNomeMae )" class="is-size-7"><b>Prim: 
									{{ props.row.geladeiraNomeMae }}/{{ props.row.containerNomeMae }}/{{ props.row.posicaoNumeroMae }}{{ props.row.posicaoLetraMae }} </b></span>
							</b-table-column>

						</template>
					</b-table>
				</div>
			</div>
			<div class="columns" v-if="!model.lista.length && isLoading">
				<div class="column is-12">
					<p><b> {{ $t('SOROTECA.NAOEXISTEMREGISTROS') }}  </b></p>
				</div>
			</div>
			<div class="columns">
				<div class="level-item center">
					<p class="buttons">
						<b-button
							type="is-danger"
							icon-left="close-circle"
							@click="$router.back()"> 
							{{ $t('SOROTECA.CANCELAR') }} 
						</b-button>
						<b-button
							type="is-success"
							icon-left="check-circle"
							@click="remover()"
							v-if="model.lista.length > 0">
							{{ $t('SOROTECA.REMOVER') }} 
						</b-button>
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
.wrap-long-cell {
	text-overflow: ellipsis;
	word-break: break-all;
}

.fix-margem-left-local-origem {
	margin-left: 0.5rem;
}

.is-default {
	background-color: #777;
	color: #fff;
}

.fix-position {
	margin-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}

.center {
	margin: 0 auto;
	padding-top: 0 !important;
}

.sigla {
	border-radius: 1rem;
	border: 1px solid gray;
	text-align: center;
	width: 2rem;
	height: 2rem;
	padding-top: 0.2rem;
	font-weight: bolder;
}

.bg-header {
	background-color: #d9edf7;
	height: 2rem;
}

.txt-obs {
	font-weight: bold;
}

td {
	cursor: pointer;
}

.center {
	margin: 0 auto;
}

.align-right {
	text-align: right;
}

.fix-label {
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	font-weight: bold;
}

.fix-margin {
	padding: 0.5rem;
}

.panel-inline {
	display: inline-flex;
}
</style>

<script>
import { mapGetters } from 'vuex';
import titlebar from '@/components/titlebar.vue';
import searchIdName from '@/components/searchIdName.vue';
import periodo from '@/components/periodo.vue';
import moment from 'moment';
import modalIncidenciaAmostra from '@/components/atendimento/modalIncidenciaAmostra'

export default {
	data() {
		return {
			model: {
				lista: [],
			},
			filtro: {
				geladeiraId: null,
				amostraCodigoDaAmostra: null,
				containerId: null,
				setorId: null,
				filtroGuia: null,
				pacienteId: null,
				dataInicial: null,
				dataFinal: null,
			},			
			total: 0,
			perPage: 20,
			numeroDeItens: 50,						
			ordenarPor: 'Nome',
			ordenar: 'asc',
			defaultOrdenar: 'asc',
			page: 1,						
			isLoading: false,
			checkedRows: [],
		};
	},
	components: {
		titlebar,
		searchIdName,
		periodo,
	},
	computed: {
		titleStack() {
			return [this.$t('SOROTECA.ANALISE'), this.$t('SOROTECA.SOROTECA')];
		},
		...mapGetters(['isInRole']),
	},
	filters: {
		datahora: (valor) => {
			if (!valor) return null;

			return moment(valor).format('DD/MM/YYYY HH:mm:ss');
		},
	},
	methods: {
		filterData() {

            const params = [`page=${this.page}`];
			
			if (this.filtro.filtroGuia) {
				params.push('filtroGuia=' + this.filtro.filtroGuia );
			}			

			if (this.filtro.geladeiraId) {
				params.push('GeladeiraId=' + this.filtro.geladeiraId );
			}			
			
			if (this.filtro.containerId) {
				params.push('ContainerId=' + this.filtro.containerId );
			}			
			
			if (this.filtro.pacienteId) {
				params.push('PacienteId=' + this.filtro.pacienteId );
			}			
			
			if (this.filtro.setorId) {
				params.push('SetorId=' + this.filtro.setorId );
			}		
			
			if (this.filtro.amostraCodigoDaAmostra) {
				params.push('amostraCodigoDaAmostra=' + this.filtro.amostraCodigoDaAmostra );
			}				
			
			if (this.filtro.dataInicial) {				
				params.push('dataInicial=' + moment(this.filtro.dataInicial).format('YYYY-MM-DDTHH:mm:ss') );
			}

			if (this.filtro.dataFinal) {
				params.push('dataFinal=' + moment(this.filtro.dataFinal).format('YYYY-MM-DDTHH:mm:ss') );
			}	
																		
			//int? page, int? filtroGuia, int? GeladeiraId, int? ContainerId, int? PacienteId, int? SetorId, string amostraCodigoDaAmostra, DateTime? dataInicial, DateTime? dataFinal
			this.isLoading = true;
			try {
				this.$http
					.get(`/api/analitico/soroteca?${params.join('&')}`)
					.then(({ data }) => {
						this.model = data;
						this.model.lista = this.model.lista?.map((i) => {
							i.checked = false;							
							return i;
						});
					
						this.total = this.perPage * this.model?.pageCount
						this.isLoading = false;
											
					})
					.catch((error) => {
						this.model = [];
						this.total = 0;
						this.isLoading = false;
						throw error;
					});
			} finally {
				this.isLoading = true;
			}
		},
		onPageChange(page) {
			this.page = page;			
			this.filterData();
		},
		retornaClasse(ex){

			if(ex.assinado){
				return 'is-success';
			}else if(ex.digitado){
				return 'is-warning';
			}else {
				return 'is-default';
			}
			
		},
		retornaTitle(ex){

			if(ex.assinado){
				return  this.$t('SOROTECA.RESULTADOASSINADO');
			}else if(ex.digitado){
				return this.$t('SOROTECA.RESULTADODIGITADO');
			}else{
				return '';
			}
			
		},  		
	
		remover() {
			const guias = this.checkedRows				
				.map((i) => {
					i.confirmar = true;
					return i;
				});

			if (!guias.length) {
				this.$buefy.toast.open({
					duration: 5000,
					message: this.$t('SOROTECA.SELECIONARAOMENOSUM') ,
					type: 'is-danger',
					queue: false,
				});

				return;
			} else {
				this.$buefy.modal.open({
                        parent: this,
                        component: modalIncidenciaAmostra,
                        props: {
                            selecionada: guias,
							titulo: this.$t('SOROTECA.AMOSTRAS'),
							removerAmostraSoroteca: true
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
			}
		},
	},
	mounted() {
		this.filterData();		
	},
};
</script>

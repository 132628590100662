<template>
    <section style="overflow: hidden;">
        

        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-9">
                <article class="tile is-child box">
                    <b-collapse
                        class="card mb-4"
                        animation="slide"
                        :open="isOpenNovoEvento"
                        >
                        
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"
                                :aria-expanded="props.open"
                            >
                                <p class="card-header-title">
                                {{ $t('PRONTUARIO.NOVOEVENTO')}} 
                                </p>
                                <a class="card-header-icon">
                                    <b-button @click="isOpenNovoEvento = isOpenNovoEvento" type="is-success"
                                            icon-left="plus">
                                        {{$t('SISTEMA.INCLUIR')}}
                                    </b-button>  
                                </a>
                            </div>
                        </template>
                        <div class="card-content mb-2">
                            <div class="content">
                                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                                    <form @submit.prevent="handleSubmit(onSubmit)">
                                        <div class="columns is-flex is-justify-content-space-between is-align-items-center" >
                                            <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                                <b-field 
                                                    :label="$t('PRONTUARIO.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors"
                                                >
                                                    <b-input maxlength="150" type="text" v-model="novoEvento.nome"></b-input>
                                                </b-field>
                                            </ValidationProvider>
                                            <div class="is-flex is-align-items-center">
                                                <p v-if="timeHours > 0 && novoEvento.nome != null">
                                                    {{hour}}
                                                </p>
                                                <p v-else-if="(timeHours == null && iniciouTimer && stopTimer) || timeHours == 0">00:</p>

                                                <p v-if="timeMinutes > 0 && novoEvento.nome != null" class="pr-3">
                                                    {{minute}}
                                                </p>
                                                <p v-else-if="(timeMinutes == null && iniciouTimer && stopTimer) || timeMinutes == 0" class="pr-3">00</p>

                                                <b-button 
                                                    @click="handleSubmit();startTimer()" 
                                                    size="is-large"
                                                    style="border: none;"
                                                >
                                                    
                                                    <b-icon
                                                        icon="clock-outline"
                                                        size="is-large"
                                                        :type="typeTimer"
                                                        >
                                                    </b-icon>
                                                </b-button>
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column is-2">    
                                                <p class="panel-heading mb-0">
                                                    {{$t('PRONTUARIO.BUSCARLAUDO')}}
                                                </p>
                                                <div class="panel-block">
                                                    <b-input v-model="laudoDigitado"
                                                        icon="magnify"
                                                        @input="procurarLaudoAutocomplete"
                                                        @keydown.native.down="laudoIndex++"
                                                        @keydown.native.up="laudoIndex--"
                                                        @keydown.native.enter.prevent="carregaLaudo(listLaudos[laudoIndex])"
                                                        ref="laudoDigitadoInput"></b-input>
                                                </div>
                                                <template v-for="(laudo, index) in filteredListLaudos" >
                                                    <template v-if="index == 0 || listLaudos[index - 1].id != laudo.id">
                                                        <a class="panel-block columns is-multiline" :key="index" @click="carregaLaudo(laudo)" v-bind:class="{ 'has-background-primary': laudoIndex == index }">
                                                            <div class="column is-6">
                                                                <p>{{ laudo.nome }}</p>
                                                            </div>
                                                        </a>
                                                    </template>
                                                </template>
                                            </div>
                                            <div class="column is-10">
                                                <ckeditor4 :editorReadOnly="false" :reportString.sync="novoEvento.report" :isEvent="false"></ckeditor4>
                                            </div>
                                        </div>
                                        <nav class="level">
                                            <div class="level-item">
                                                <p class="buttons">
                                                    <b-button type="is-success"
                                                            :loading="salvandoDados"
                                                            native-type="submit"
                                                            icon-left="check-circle">
                                                        {{$t('SISTEMA.SALVAR')}}
                                                    </b-button>
                                                    <b-button type="is-danger"
                                                            native-type="button"
                                                            icon-left="close-circle"
                                                            @click="isOpenNovoEvento = !isOpenNovoEvento">
                                                        {{$t('SISTEMA.CANCELAR')}}
                                                    </b-button>
                                                </p>
                                            </div>
                                        </nav>
                                    </form>
                                </ValidationObserver>
                            </div>
                        </div>
                    </b-collapse>

                    <b-collapse
                        class="card"
                        animation="slide"
                        v-for="(lista, index) of listaEventosExames"
                        :key="index"
                        :open="isOpen == index"
                        @open="isOpen = index">
                        
                        <template #trigger="props">
                            <div
                                class="card-header mb-5 is-align-items-center "
                                role="button"
                                :aria-expanded="props.open"
                            >
                                <b-icon v-if="lista.evento" icon="file-plus-outline" size="is-large" type="is-succes" ></b-icon>
                                <b-icon v-else icon="file-document-outline" size="is-large" type="is-succes"></b-icon>
                                <div class="card-header-title">
                                    <p>{{ $moment(lista.dataSolicitacao).format('DD/MM/YYYY HH:mm') }} </p>
                                    <small class="ml-2"> - {{ lista.titulo }} </small> 
                                </div>

                                <p v-if="lista.guiaId > 0 && lista.guiaId != null"><b>{{lista.guiaId}}</b></p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>
                        <div class="card-content mb-2 is-flex is-align-content-center  is-justify-content-center">
                            <div class="content">
                                <div v-if="lista.guiaId > 0 && lista.guiaId != null" class="panel-heading mx-1">
                                    <div  class="is-flex is-align-content-center">
                                        <p class="mr-6">Solicitante: {{lista.solicitanteNome}}</p>
                                        <p>Convênio: {{lista.convenioNome}}</p>
                                    </div>
                                </div>
                                <div>
                                    <ckeditor4 :editorReadOnly="true" :isEvent="lista.evento" :id="index" :reportString.sync="lista.report" @updateEvento="updateEvento(lista)" @pegaEventoAtual="pegaEventoAtual = true"></ckeditor4>
                                </div>
                                <nav class="level">
                                    <div class="level-item">
                                        <p class="buttons">
                                            <a  v-if="lista.estudoId != null && lista.estudoId > 0" :href="'/Guia/Pdf?id=' + lista.guiaId + '&sel=' + lista.item" target="_blank">
                                                <b-button class="button has-background-grey-light" title="Laudo Estruturado">
                                                    <b><b-icon icon="file" size="is-small"></b-icon>&nbsp;&nbsp;{{$t('PRONTUARIO.LAUDO')}}</b>
                                                </b-button>
                                            </a>
                                            <a v-if="lista.estudoId != null && lista.estudoId > 0 && lista.viewers != null" :href="lista.viewers[0].url" target="_blank">
                                                <b-button class="button has-background-grey-light" title="viewer">
                                                    <b><b-icon icon="eye" size="is-small"></b-icon>&nbsp;&nbsp;{{$t('PRONTUARIO.IMAGEM')}}</b>
                                                </b-button>
                                            </a>
                                        </p>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </b-collapse>

                </article>
            </div>

            <div class="tile is-parent is-3">
                <article class="tile is-child is-12 is-fullheight">
                    <article class="panel ">
                        <div>                                    
                            <div class="panel-block has-text-centered">
                                <figure class="image is-64x64 has-image-centered">
                                    <img v-if="this.model?.foto" class="is-rounded" :src="model.foto" ref="foto">
                                    <img v-else class="is-rounded" src="../../assets/pessoa.png">
                                </figure>
                            </div>

                            <div class="column has-background-white">
                                <p class="columns is-flex is-justify-content-space-around pb-3 is-size-5 is-align-items-center">
                                    <b>{{model.nome}}</b>
                                    <b-button icon-left="pencil" style="border: none; outline: none;"
                                    tag="router-link"
                                    :to="{ name: 'paciente', params: { id: model.id }}"
                                    >
                                    </b-button>
                                </p>

                                <div class="columns is-flex is-justify-content-space-between px-4">
                                    <p class="is-align-items-center  pr-5"> 
                                        <b-icon icon="pencil" ></b-icon>
                                        <b>{{$t('PRONTUARIO.CODIGO')}}</b>
                                    </p>
                                    <p>{{model.id}}</p>
                                </div>
                                <div class="columns is-flex is-justify-content-space-between px-4">
                                    <p class="is-align-items-center pr-5"> 
                                        <b-icon icon="star" ></b-icon>
                                        <b>{{$t('PRONTUARIO.IDADE')}}</b>
                                    </p>
                                    <p>{{this.idade}}</p>
                                </div>
                                <div class="columns is-flex is-justify-content-space-between px-4">
                                    <p class="is-align-items-center pr-5"> 
                                        <b-icon icon="account" ></b-icon>
                                        <b>{{$t('PRONTUARIO.SEXO')}}</b>
                                    </p>
                                    <p>{{model.sexo === "F" ? $t('PRONTUARIO.FEMININO') : $t('PRONTUARIO.MASCULINO')}}</p>
                                </div>
                                <div class="columns is-flex is-justify-content-space-between px-4">
                                    <p class="is-align-items-center pr-5"> 
                                        <b-icon icon="cellphone" ></b-icon>
                                        <b>{{$t('PRONTUARIO.CELULAR')}}</b>
                                    </p>
                                    <p>{{model.celular}}</p>
                                </div>
                                <div class="columns is-flex is-justify-content-space-between px-4">
                                    <p class="is-align-items-center pr-5"> 
                                        <b-icon icon="phone" ></b-icon>
                                        <b>{{$t('PRONTUARIO.TELEFONE')}}</b>
                                    </p>
                                    <p>{{model.fone}}</p>
                                </div>
                                <div class="columns is-flex is-justify-content-space-between px-4">
                                    <p class="is-align-items-center pr-5"> 
                                        <b-icon icon="bookmark" ></b-icon>
                                        <b>{{$t('PRONTUARIO.CONVENIO')}}</b>
                                    </p>
                                    <p>{{model.convenioPadraoNome}}</p>
                                </div>

                            </div>

                        </div>
                    </article>

                    <article v-if="model.id" class="panel">
                        <div class="panel-heading has-text-centered">
                            {{$t('PACIENTECADASTRO.DOC')}}
                        </div>
                        <p v-if="carregando" class="panel-tabs">
                            <b-loading :active.sync="carregando"></b-loading>
                        </p>
                        <div v-if="!arquivosCarregados" class="panel-block">
                            <b-button expanded @click="buscarHistoricoArquivos">Listar...</b-button>
                        </div>
                        <a class="panel-block" v-for="(item, index) in historicoArquivos" v-bind:key="index" @click="buscarArquivo(index, item.extensao);">
                            <span class="panel-icon">
                                <i class="fas fa-book" aria-hidden="true"></i>
                            </span>
                            <pre :id="'arquivo_' + index" style="display:none;">{{item.arquivo64}}</pre>
                            {{item.nome}}
                        </a>
                        <p v-if="arquivosCarregados && (historicoArquivos == null || historicoArquivos.length == 0) " class="panel-block">
                            {{$t('PACIENTECADASTRO.NENHUMDOC')}}
                        </p>
                    </article>

                    <article class="panel">
                        <div class="panel-heading has-text-centered">
                            {{$t('PACIENTECADASTRO.CODINTEG')}}
                        </div>
                        <div class="panel-block">

                            <div class="b-table tile is-12">                                    
                                <div class="table-wrapper has-mobile-cards tile is-12">
                                    <table class="table is-striped is-hoverable">
                                        <thead>
                                            <tr>                                                
                                            <th class="">
                                                <div class="th-wrap">
                                                    {{$t('PACIENTECADASTRO.TIPO')}}
                                                </div>
                                            </th>
                                            <th class="">
                                                <div class="th-wrap">
                                                    {{$t('PACIENTECADASTRO.CODIGO')}} 
                                                </div>
                                            </th>                                                
                                            </tr>                                   
                                        </thead>
                                        <tbody>

                                        <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                            <td>
                                                <b-select :placeholder="$t('PACIENTECADASTRO.INTEG')"
                                                    disabled
                                                    v-model="item.integradorId" class="adicionar-codigo-integracao">
                                                    <option v-for="option in integradores"
                                                            :value="option.id"
                                                            :key="option.id">
                                                        {{ option.nome }}
                                                    </option>
                                                </b-select>
                                            </td>
                                            <td>
                                                <input disabled class="input adicionar-codigo-integracao" type="text" v-model="item.codigo" >
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        
                    </article>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import ckeditor4 from '@/components/ckeditor4.vue';
    import debounce from 'lodash/debounce'
    import calcularIdade  from '../../assets/js/views/paciente-idade/calcularIdade.js'

    export default {
        data() {
            return {
                pegaEventoAtual: false,
                salvandoDados: false,
                model:  null,
                novoEvento: {
                    report: ""
                },
                salvarEvento: null,
                carregando: true,
                historicoArquivos: [],
                arquivosCarregados: false,
                isOpen: null,
                isOpenNovoEvento: false,
                listaEventosExames: [],
                laudoDigitado: null,
                listLaudos: [],
                isFetching: false,
                laudoIndex: 0,
                incluirEvento: [],
                iniciouTimer: false,
                minute: null,
                hour: null,
                timeHours: null,
                timeMinutes: null,
                typeTimer: 'is-info',
                intervalMinutes: null,
                stopTimer: true,
            };
        },
        components: {
            ckeditor4,
        },     
        async mounted(){
            this.componentes.sidebarIsOpened = true;

            await this.$http.get('/api/manutencao/paciente?id=' + this.$route.params.id)
            .then(res => {
                this.model = res.data;

                if(this.model.dataDeNascimento){
                    this.model.dataDeNascimento = new Date(this.model.dataDeNascimento)
                }
                
                if (this.model?.foto){
                    if (this.model.foto.indexOf('data:image/') < 0){
                        this.model.foto = ` data:image/*;charset=utf-8;base64,${this.model.foto}`;
                    }
                }
            });

            await this.loadProntuario();
            await this.loadListaLaudos();
        },
        beforeDestroy() {
            this.componentes.sidebarIsOpened = true;
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'componentes',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            filteredListLaudos() {
                return this.laudoDigitado
                    ? this.listLaudos
                        .filter(x => x.nome.toUpperCase().indexOf(this.laudoDigitado.toUpperCase()) > -1)
                    : this.listLaudos
            },
            idade() {
                if(this.model.dataDeNascimento == null){
                    return "";
                }

                return this.calcularIdade();
            },
        },
        watch: {
            timeHours(hour) {
                if(hour == 13) {
                    this.timeHours = 0;
                }

                this.hour = hour >= 10 ? `${hour}:` : `0${hour}:`
            },
            timeMinutes(minute) {
                if(minute == 61) {
                    this.timeMinutes = 0;
                }
                
                this.minute = minute >= 10 ? minute : `0${minute}`
            },
        },
        methods: {
            onSubmit(saveOnStartTimer){
                const itens = this.salvarEvento ?? this.novoEvento
                this.salvandoDados = true;
                this.model.cpf = null

                itens.paciente = this.model;

                if(!saveOnStartTimer) {
                    itens.dataDeConclusao = new Date();

                    if(this.novoEvento.dataDeInicio == null) {
                        itens.dataDeInicio = new Date();
                    }

                    if(!this.stopTimer) {
                        this.typeTimer = 'is-info'
                        this.iniciouTimer = false;
                        
                        clearInterval(this.intervalMinutes);
                        clearInterval(this.intervalHours);

                        this.minute = null;
                        this.hour = null;
                        this.timeMinutes = null;
                        this.timeHours = null;
                    }

                }


                this.$http.post('/api/manutencao/SalvarEventoProntuario', itens)
                .then(({data}) => {
                    if(saveOnStartTimer) {
                        this.novoEvento.id = data
                    }else {
                        this.novoEvento = null;
                        this.stopTimer = false;

                        this.novoEvento = {
                            report: ""
                        };
                        
                        this.isOpenNovoEvento = false;
                        this.loadProntuario()
                    }

                    this.salvandoDados = false;
                })
                .catch(err => {
                    this.salvandoDados = false;
                    console.error(err)
                    Object.keys(err.body.errors).forEach(x => {
                        this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                    })
                });
            },
            updateEvento(evento) {
                this.salvarEvento = {
                    id: evento.prontuarioId,
                    report: evento.report,
                } 
                this.onSubmit();               
            },
            loadProntuario() {
                this.$http.get(`/api/manutencao/prontuario?pacienteId=${this.model.id}`)
                .then(({ data }) => {
                    this.listaEventosExames = data
                    this.carregando = false;
                })
                .catch((error) => {
                    throw error
                })
            },
            startTimer() {
                if(!this.iniciouTimer && this.novoEvento?.nome) {
                    this.iniciouTimer = true;
                    this.stopTimer = false;
                    this.typeTimer = 'is-success';

                    this.timeMinutes = 0;
                    this.timeHours = 0;

                    this.intervalMinutes = setInterval(() => {
                        this.timeMinutes++
                    }, 60000)

                     this.intervalHours = setInterval(() => {
                        this.timeHours++
                    }, 3600000)

                    this.novoEvento.dataDeInicio = new Date();
                    this.onSubmit(true);
                }
                else {
                    this.typeTimer = 'is-info'
                    this.iniciouTimer = false;
                    
                    clearInterval(this.intervalMinutes);
                    clearInterval(this.intervalHours);
                    
                    this.novoEvento.DataDeFim = new Date();

                    this.minute = this.minute != null && this.minute >= 10 
                        ? this.minute 
                        : this.minute != null 
                            ? this.minute
                            : '00';

                    this.hour = this.hour != null && this.hour >= 10 
                        ? this.hour 
                        : this.hour != null 
                            ? this.hour
                            : '00:';

                    this.stopTimer = true;
                }
            },
            buscarArquivo(index, extensao) {
                const base64Data = document.getElementById("arquivo_" + index).innerText;

                const sliceSize = 512;
                const byteCharacters = window.atob(base64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                
                const blob = new Blob(byteArrays, {
                    type: 'application/' + extensao
                });

                const blobURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = blobURL;
                link.download = "Documento." + extensao;
                link.click();
                window.URL.revokeObjectURL(blobURL);
                link.remove();
            },
            buscarHistoricoArquivos() {
                this.carregando = true
                this.$http.get('/api/atendimento/historicoArquivosPaciente?pacienteId=' + this.$route.params.id)
                .then(res => {
                    this.carregando = false
                    this.historicoArquivos = res.data
                    this.arquivosCarregados = true
                })
                .catch(err => {
                    this.carregando = false;
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('PACIENTECADASTRO.ERROHIST') + err.body.title,
                        type: 'is-danger',
                        queue: false
                    })
                })
            },
            carregaLaudo(laudo){
                if (!laudo){
                    this.$buefy.dialog.alert({
                        title: this.$t('PRONTUARIO.TITULOMODELOLAUDO'),
                        message: this.$t('PRONTUARIO.MESANGEMMODELOLAUDO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                let html = laudo.html
                html = html ? `</br> ${html}` : html
                this.novoEvento.report += html
            },
            procurarLaudoAutocomplete: debounce(function () {
                this.loadListaLaudos();
            }),
            loadListaLaudos(){
                this.isFetching = true
                        
                this.$http.get('/api/modelolaudodescritivo/ListaLaudosEvento?')
                    .then(({ data }) => {
                        this.listLaudos = data;
                        this.laudoIndex = 0;
                        this.$refs.laudoDigitadoInput.focus()
                    })
                    .catch((error) => {
                        this.listLaudos = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })

            },
            calcularIdade(){
                return calcularIdade.Idade(this.model.dataDeNascimento, this);
            },
        }
    }
</script>
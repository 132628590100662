import { render, staticRenderFns } from "./atendimento.vue?vue&type=template&id=2c3a968d"
import script from "./atendimento.vue?vue&type=script&lang=js"
export * from "./atendimento.vue?vue&type=script&lang=js"
import style0 from "./atendimento.vue?vue&type=style&index=0&id=2c3a968d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
    <section>
        <div style="padding: 1rem; border-bottom: 1px solid black;">
            <span style="display: flex; align-items: center">
                <strong> Pacientes </strong>
            </span>
        </div>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('PACIENTESLISTA.PROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div >
                    <router-link v-if="isInRole('paciente-alterar')" :to="{ name: 'paciente', params: { criarGuia: criarGuia, nome: procurar, agendamento: agendamento, agendamentoDashboard: agendamentoDashboard, convenioIdAgendar: convenioIdAgendar, observacoesInspecao:observacoesInspecao, executanteId: executanteId }}" class="button is-success">
                        {{$t('PACIENTESLISTA.INCLUIR')}}
                    </router-link>
                    <a class="button is-success" href="/manutencao/UnificarPaciente">{{$t('PACIENTESLISTA.UNIRCADASTRO')}}</a>
                </div>
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('PACIENTESLISTA.CODIGO')"
                                 v-model="id"
                                 type="number"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('PACIENTESLISTA.PROXPAGINA')"
                 :aria-previous-label="$t('PACIENTESLISTA.PAGANTERIOR')"
                 :aria-page-label="$t('PACIENTESLISTA.PAGINA')"
                 :aria-current-label="$t('PACIENTESLISTA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"                 
                 @sort="onSort">

                <template >
                
                <b-table-column field="id" :label="$t('PACIENTESLISTA.ID')" v-slot="props" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="Nome" :label="$t('PACIENTESLISTA.NOME')" v-slot="props" sortable>
                    {{ props.row.nome }}
                </b-table-column>

                <b-table-column field="Sexo" :label="$t('PACIENTESLISTA.SEXO')" v-slot="props" sortable>
                    {{ props.row.sexo }}
                </b-table-column>

                <template v-if="config.laboratorioVeterinario">
                    <b-table-column field="Especie" :label="$t('PACIENTESLISTA.ESPECIE')" v-slot="props" sortable>
                        {{ props.row.pacienteEspecie }}
                    </b-table-column>
    
                    <b-table-column field="Raca" :label="$t('PACIENTESLISTA.RACA')" v-slot="props" sortable>
                        {{ props.row.pacienteRaca }}
                    </b-table-column>
                </template>

                <b-table-column field="DataDeNascimento" :label="$t('PACIENTESLISTA.NASCIMENTO')" v-slot="props" sortable centered>
                    {{ props.row.dataDeNascimentoIdade }}
                </b-table-column>

                <b-table-column field="Email" :label="$t('PACIENTESLISTA.EMAIL')" v-slot="props" sortable>
                    {{ props.row.email }}
                </b-table-column>

                <b-table-column field="Responsavel" :label="$t('PACIENTESLISTA.RESPONSAVEL')" v-slot="props">
                    {{ props.row.nomeResponsavel }}
                </b-table-column>

                <b-table-column field="desativado" label="" v-slot="props">
                    <b-icon v-if="props.row.desativado" type="is-danger" icon="close-circle" :title="$t('PACIENTESLISTA.DESATIVADO')"></b-icon>
                </b-table-column>

                <b-table-column custom-key="actions" numeric v-slot="props">
                    <router-link :to="{ name: 'paciente', params: { id: props.row.id, criarGuia: criarGuia, agendamento: agendamento, observacoesInspecao: observacoesInspecao,  agendamentoDashboard: agendamentoDashboard, convenioIdAgendar: convenioIdAgendar, executanteId: executanteId }}" class="button is-small is-primary" :title="$t('PACIENTESLISTA.EDITAR')">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>

                </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? this.$route.params.procurar ?? '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null,
                criarGuia: Boolean(this.$route.params.criarGuia) ?? false,
                agendamento: this.$route.params.agendamento ? this.$route.params.agendamento : null,                
                observacoesInspecao: this.$route.params.observacoesInspecao ? this.$route.params.observacoesInspecao : null,                
                agendamentoDashboard: this.$route.params.agendamentoDashboard,
                convenioIdAgendar: this.$route.params.convenioIdAgendar ? this.$route.params.convenioIdAgendar : null,
            }
        },
        components: {
        },
        computed: {
            titleStack() {
                return [
                    this.$t('PACIENTESLISTA.MANUTENCAO'),
                    this.$t('SISTEMA.PACIENTE')+"s",
                ]
            },
            ...mapState([
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            loadAsyncData: debounce(function () {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `retornarDesativados=false`                      
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/pacientes?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        if(this.procurar != ""){
                            this.$router.replace({query: {procurar: this.procurar, page: this.page }})
                        }
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            }, 500),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.$router.push({
                    name: 'paciente', params: {
                        id: this.selecionado.id,
                        criarGuia: this.criarGuia,
                        agendamento: this.agendamento,  
                        observacoesInspecao: this.observacoesInspecao
                    }
                })
            },
            abrirCadastroInput() {
                this.$router.push({
                    name: 'paciente', params: {
                        id: this.id,
                        criarGuia: this.criarGuia,
                        agendamento: this.agendamento,
                        observacoesInspecao: this.observacoesInspecao
                    }
                })
            },
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>
<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div class="columns is-multiline">
                <div class="column is-6">
                    <b-field :label="$t('PROCURAEXAME.NUMEROGUIA')">
                        <b-input type="number" v-model="guiaId"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" :label="$t('PROCURAEXAME.UNIDADE')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="local.id" :item.sync="local" table="Local" :label="$t('PROCURAEXAME.LOCAL')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" :label="$t('SISTEMA.CONVENIO')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="paciente.id" :item.sync="paciente" table="Paciente" :label="$t('SISTEMA.PACIENTE')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="responsavel.id" :item.sync="responsavel" table="Paciente" :label="$t('PROCURAEXAME.RESPONSAVEL')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="setor.id" :item.sync="setor" table="Setor" :label="$t('PROCURAEXAME.SETOR')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="exame.id" :item.sync="exame" table="Exame" :label="$t('PROCURAEXAME.EXAME')" searchField="texto" :campos="[{nome:'Apelido', campo: 'apelido'},{nome: 'Setor', campo: 'setorNome'}]"/>
                </div>
                <div class="column is-6">
                    <b-field :label="$t('PROCURAEXAME.EXAMESTATUS')">
                        <b-taginput
                            v-model="statusSelecionados"
                            :data="statusLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.statusLista = this.status; return item;}"
                            :open-on-focus="openOfFocus"
                            field="status"
                            icon="label"
                            :placeholder="$t('PROCURAEXAME.SELECIONESTATUS')"
                            @typing="buscarStatus"
                            ref="tagInput"
                            >
                        </b-taginput>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="examesAtrasados">{{$t('PROCURAEXAME.EXAMEATRASADO')}}</b-checkbox>
                    </b-field>
                </div>                
                <div class="column is-6">
                    <div class="panel">
                        <p class="panel-tabs">
                            <a :class="{ 'is-active': dataFiltro == 'emissao' }" @click="dataFiltro = 'emissao'">{{$t('PROCURAEXAME.EMISSAO')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'solicitacao' }" @click="dataFiltro = 'solicitacao'">{{$t('PROCURAEXAME.SOLICITACAO')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'coleta' }" @click="dataFiltro = 'coleta'">{{$t('PROCURAEXAME.COLETA')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'retorno' }" @click="dataFiltro = 'retorno'">{{$t('PROCURAEXAME.RETORNO')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'entrega' }" @click="dataFiltro = 'entrega'">{{$t('PROCURAEXAME.ENTREGA')}}</a>
                        </p>
                        <div class="panel-block">
                            <periodoHora :dataInicial.sync="datas[0]"
                                 :dataFinal.sync="datas[1]"/>
                        </div>
                        <div class="colum">
                            <b-field class="is-pulled-right" :label="$t('PROCURAEXAME.RESULTADOPAGINA')">
                                <b-select class="is-pulled-right" v-model="perPage" :disabled="!isPaginated">
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="has-text-centered">
                <b-button type="is-success" :loading="loading" @click="carregarExames()">Buscar</b-button>
            </div>
        </div>
        
        <div class="box" v-if="exames.length > 0">
            <div class="columns">
                <div class="column  is-justified-between">
                    <span class="label">{{$t('PROCURAEXAME.EXAMES')}}</span>
                    <b-modal :active.sync="isModalAlteracaoPrazo" has-modal-card trap-focus aria-role="dialog" aria-modal>
                        <modalAlteracaoPrazo :motivos="motivosAlteracao" :data.sync="checkedRows"></modalAlteracaoPrazo>
                    </b-modal>
                    <b-button v-if="checkedRows != null && checkedRows.length > 0" type="is-success" @click="isModalAlteracaoPrazo = true">
                        {{$t('PROCURAEXAME.ALTERARPRAZO')}}
                    </b-button>
                </div>
            </div>
            <b-table :data="exames"
                 :loading="loading"
                 striped
                 :total="total"
                 :paginated="isPaginated"
                 backend-pagination
                 
                 @page-change="onPageChange"
                 :aria-next-label="$t('PROCURAEXAME.PROXPAGINA')"
                 :aria-previous-label="$t('PROCURAEXAME.PAGINAANTERIOR')"
                 :aria-page-label="$t('PROCURAEXAME.PAGINA')"
                 :aria-current-label="$t('PROCURAEXAME.PAGINAATUAL')"
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition"
                :per-page="perPage">

                <template >
                    <b-table-column  v-slot="props" field="Número da Guia" :label="$t('PROCURAEXAME.NUMEROGUIA')">
                        <router-link :to="{ name: 'guia', params: { id: props.row.guiaId }}">
                            {{ props.row.guiaId }}
                        </router-link>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Nome do Paciente" :label="$t('PROCURAEXAME.PACIENTENOME')">
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <!--<b-table-column field="Código da Amostra" label="Código da Amostra">
                        {{ props.row.amostraCodigo }}
                    </b-table-column>-->

                    <b-table-column  v-slot="props" field="Apelido" :label="$t('PROCURAEXAME.APELIDO')">
                        <a v-if="props.row.status & 4 && !(props.row.status & 1)" :href="'/guia/resultado?guiaId=' + props.row.guiaId + '&item=' + props.row.item">
                            {{ props.row.exameApelido }}
                        </a>
                        <span v-else>
                            {{ props.row.exameApelido }}
                        </span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Data de Entrega" :label="$t('PROCURAEXAME.DATAENTREGA')">
                        <span v-if="props.row.dataEntregaExame">
                            {{ $moment(props.row.dataEntregaExame).format("DD/MM/YYYY") }}
                        </span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Observações" :label="$t('PROCURAEXAME.OBSERVACOES')">
                        {{ props.row.obsExame }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Status" :label="$t('PROCURAEXAME.STATUS')">
                        <span class="tag" :title="props.row.statusTitle" :style="props.row.statusHtmlStyle">
                            {{ props.row.statusSigla }}
                        </span>
                    </b-table-column>
                </template>
            </b-table>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'    
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import modalAlteracaoPrazo from '@/components/atendimento/modalAlteracaoPrazo.vue'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        components: {
            titlebar,
            searchIdName,
            modalAlteracaoPrazo,
            periodoHora
        },
        computed: {
            titleStack() {
                return [
                    this.$t('PROCURAEXAME.ATENDIMENTO'),
                    this.$t('PROCURAEXAME.PROCURAEXAME'),                    
                ]
            },                     
            ...mapState([
                'status',
            ])
        },
        created() {
            // se passou algum parametro
            if (this.$route.query) {
                if (this.$route.query.setorid) {
                    this.setor.id = parseInt(this.$route.query.setorid)
                }
                if (this.$route.query.unidadeId) {
                    this.unidade.id = parseInt(this.$route.query.unidadeId)
                }
                if (this.$route.query.convenioId) {
                    this.convenio.id = parseInt(this.$route.query.convenioId)
                }
                if (this.$route.query.contabilizarEntreguesAtrasados) {
                    this.contabilizarEntreguesAtrasados = this.$route.query.contabilizarEntreguesAtrasados
                }
                if (this.$route.query.status) {
                    switch (this.$route.query.status) {
                        case 'Atrasados':
                            this.examesAtrasados = true
                            break;
                        case 'Aguardando material':
                            this.statusSelecionados = ['Solicitado']
                            break;
                        case 'Material entregue':
                            this.statusSelecionados = ['MaterialEntregue']
                            break;
                        case 'Bancada emitida':
                            this.statusSelecionados = ['BancadaEmitida']
                            break;
                        case 'Digitados':
                            this.statusSelecionados = ['ResultadoDigitado']
                            break;
                        case 'Assinados':
                            this.statusSelecionados = ['ResultadoAssinado']
                            break;
                    }
                }
                if (this.$route.query.dias) {
                    this.datas = [
                        this.$moment().subtract(this.$route.query.dias, 'days').toDate(),
                        this.$moment().toDate()
                    ]
                }
                this.carregarExames()
            }
        },
        watch: {
            status: function(newValue){
                if (newValue){
                    this.statusLista = newValue;
                }
            },
        },
        data() {
            return {
                guiaId: null,
                openOfFocus: true,
                local: {
                    id: null
                },
                unidade: {
                    id: null
                },
                convenio: {
                    id: null
                },
                paciente: {
                    id: null
                },
                responsavel: {
                    id: null
                },
                setor: {
                    id: null
                },
                exame: {
                    id: null
                },
                datas: [
                    this.$moment().subtract(1, 'days').toDate(),
                    this.$moment().toDate()
                ],
                dataFiltro: 'solicitacao',
                statusSelecionados: [],
                statusLista: this.status,
                exames: [],
                loading: false,
                checkedRows: [],
                checkboxPosition: 'left',
                page: 1,
                total: 0,
                isModalAlteracaoPrazo: false,
                motivosAlteracao: [],
                examesAtrasados: false,
                perPage: 10,
                isPaginated: true,
                contabilizarEntreguesAtrasados: false,
            }
        },
        methods: {
            buscarStatus(text) {
                this.statusLista = this.status;
                this.statusLista = this.statusLista.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            carregarExames(){

                //this.$router.replace({
                //    query: {
                //        setorid: this.setor.id
                //    }
                //})

                const params = [
                    `pageNumber=${this.page}`,
                    `filtrarPor=${this.dataFiltro}`,
                    `statusExames=${this.statusSelecionados.toString()}`,
                    `dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`,
                    `perPage=${this.perPage}`,
                    `contabilizarEntreguesAtrasados=${this.contabilizarEntreguesAtrasados}`
                ]
                if(this.unidade?.id != null)
                {
                    params.push(`unidadeId=${this.unidade.id}`)
                }

                if(this.guiaId != null){
                    params.push(`guiaId=${this.guiaId}`);
                }

                if(this.local != null && this.local.id != null){
                    params.push(`localId=${this.local.id}`);
                }

                if(this.setor != null && this.setor.id != null){
                    params.push(`setorId=${this.setor.id}`);
                }

                if(this.exame != null && this.exame.id != null){
                    params.push(`exameId=${this.exame.id}`);
                }

                if(this.convenio != null && this.convenio.id != null){
                    params.push(`convenioId=${this.convenio.id}`);
                }

                if(this.paciente != null && this.paciente.id != null){
                    params.push(`pacienteId=${this.paciente.id}`);
                }

                if(this.responsavel != null && this.responsavel.id != null){
                    params.push(`responsavelId=${this.responsavel.id}`);
                }

                if (this.examesAtrasados) {
                    params.push('examesAtrasados=true')
                }

                this.loading = true;
                this.$http.get(`/api/atendimento/procuradeexames?${params.join('&')}`)
                    .then(({ data }) => {
                        this.exames = data.lista
                        this.total = data.pageCount * 10
                        this.loading = false
                    })
                    .catch((error) => {
                        this.total = 0
                        this.loading = false
                        throw error
                    })

                if(this.motivosAlteracao.length == 0){
                    this.$http.get(`/api/search/motivosalteracaoprazo`)
                    .then(({ data }) => {
                        this.motivosAlteracao = data
                    })
                }
                
            },
            onPageChange(page) {
                this.page = page
                this.carregarExames()
            }
        }
    }
</script>
<template>
    <div>                    
        <div class="box is-hero-bar is-main-hero" v-if="recoletas != null && recoletas.length > 0">
            <div class="panel">
                <div v-for="(amostra, index) in recoletas" :key="index">
                    <div class="panel-block is-block">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-7-desktop is-full-mobile">
                                <div class="columns is-mobile is-multiline">
                                    <div class="column is-half-mobile">
                                        <span>
                                            <small>{{$t('RECOLETAS.PEDIDO')}}:</small>
                                            <br>
                                            <router-link :to="{ name: 'portalconvenioguia', params: { id: amostra.guiaId }}">
                                                <b >{{amostra.guiaId}}</b>
                                            </router-link>
                                        </span>
                                    </div>
                                    <div class="column is-half-mobile">
                                        <small>{{$t('RECOLETAS.CODIGOBARRAS')}}:</small>
                                        <br>
                                        <b>{{amostra.codigoDeBarras}}</b>
                                    </div>
                                    <div class="column is-full-mobile">
                                        <small>{{$t('RECOLETAS.SOLICITADA')}}:</small>
                                        <br>
                                        <b>{{$moment(amostra.dataSolicitacao).format("DD/MM/YYYY HH:mm")}}</b>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-12">
                                        <small>{{$t('RECOLETAS.MOTIVO')}}:</small>
                                        <br>
                                        <span>{{amostra.motivoRecoleta}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-5-desktop is-full-mobile">
                                <div class="columns is-mobile">
                                    <div class="column is-10">
                                        <small>{{$t('RECOLETAS.EXAMES')}}:</small>
                                        <br>
                                        <b-tag v-for="(exameApelido, j) in amostra.examesApelidos" v-bind:key="j"
                                               :type="'default'">
                                            {{ exameApelido }}
                                        </b-tag>
                                    </div>
                                    <div class="column is-2 has-text-right">
                                        <b-tooltip :label="$t('RECOLETAS.ETIQUETA')" multilined position="is-left">
                                            <a class="is-primary button portal-botoes-convenio-guia"
                                               @click="imprimirEtiquetaAmostra(amostra)">
                                                <b-icon icon="barcode" size="is-small"></b-icon>
                                            </a>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {mapState } from 'vuex'

    export default {
        props: {
            recoletas: Array
        },
        data() {
            return {
            };
        },    
        computed: {           
            ...mapState([
                'config',
                'usuario'
            ])
        },
        methods:{
            imprimirEtiquetaAmostra(amostra){
                this.$notificacaoHub.invoke("EtiquetaAmostra",
                    [amostra.id],
                    null,false)
                    .then(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Impressão de etiqueta solicitada para amostra: ' + amostra.id,
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
                    .catch(e => {
                        return console.error(e.toString());
                    })
            },
            carregarRecoletas(){
                if(this.recoletasCount != 0){
                    this.$http.get('/api/portal/Recoletas')
                        .then(res => {
                            this.recoletas = res.data;
                        });
                }
            },          
        },

        created(){
            this.carregarRecoletas();
        }, 

    }

</script>